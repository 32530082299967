import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Datenschutz = () => (
  <Layout>
    <Seo title="Hochschulsportverband Niedersachsen/Bremen" />
    <section className="pt-10">

      <div className="container">
          <span className="badge badge-pill badge-primary-soft mb-3">
            <span className="h6 text-uppercase">Datenschutz</span>
          </span>
          <h2>Datenschutz</h2>
          <div className="row">
              <div className="col">
                <p>Der Verantwortliche im Sinne der EU-Datenschutzgrundverordnung (DSGVO) und anderer nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist:</p>
                <h5>Hochschulsportverband Niedersachsen/Bremen</h5>
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                  - Geschäftsstelle -<br />
                  c/o Zentrum für Hochschulsport Hannover<br />
                  Am Moritzwinkel 6<br />
                  30167 Hannover<br />
                  <a href="mailto:info@zfh.uni-hannover.de">info@zfh.uni-hannover.de</a>
                </p>
                
                <h5>
                  Verantwortlich im Sinne des Presserechts gemäß § 55 Abs. 2 RStV:
                </h5>
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Sebastian Knust<br />
                Zentrum für Hochschulsport Hannover<br />
                Am Moritzwinkel 6<br />
                30167 Hannover<br />
                Tel.: 0511 762 4964 <br />
                <a href="mailto:info@zfh.uni-hannover.de">knust@zfh.uni-hannover.de</a>
              </p>
              <h5>Zugriffsdaten/Server-Logfiles</h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Der Anbieter (beziehungsweise sein Webspace-Provider) erhebt Daten über jeden Zugriff auf das Angebot (so genannte Server-Logfiles). Zu den Zugriffsdaten gehören: Name der abgerufenen Website, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
              </p>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Der Anbieter verwendet die Protokolldaten nur für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Angebotes. Der Anbieter behält sich jedoch vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht.
              </p>
              <h5>Umgang mit personenbezogenen Daten</h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Personenbezogene Daten sind Informationen, mit deren Hilfe eine Person bestimmbar ist, also Angaben, die zurück zu einer Person verfolgt werden können. Dazu gehören der Name, die E-Mail-Adresse oder die Telefonnummer.
              </p>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Personenbezogene Daten werden von dem Anbieter nur dann erhoben, genutzt und weiter gegeben, wenn dies gesetzlich erlaubt ist oder die Nutzer in die Datenerhebung einwilligen.
              </p>
              <h5>Kontaktaufnahme</h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Bei der Kontaktaufnahme mit dem Anbieter (zum Beispiel per Kontaktformular oder E-Mail) werden die Angaben des Nutzers zwecks Bearbeitung der Anfrage sowie für den Fall, dass Anschlussfragen entstehen, gespeichert.
              </p>
              <h5>Cookies</h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Cookies sind kleine Dateien, die es ermöglichen, auf dem Zugriffsgerät der Nutzer (PC, Smartphone o.ä.) spezifische, auf das Gerät bezogene Informationen zu speichern. Sie dienen zum einem der Benutzerfreundlichkeit von Webseiten und damit den Nutzern (z.B. Speicherung von Logindaten). Zum anderen dienen sie, um die statistische Daten der Webseitennutzung zu erfassen und sie zwecks Verbesserung des Angebotes analysieren zu können. Die Nutzer können auf den Einsatz der Cookies Einfluss nehmen. Die meisten Browser verfügen eine Option mit der das Speichern von Cookies eingeschränkt oder komplett verhindert wird. Allerdings wird darauf hingewiesen, dass die Nutzung und insbesondere der Nutzungskomfort ohne Cookies eingeschränkt werden.
              </p>
              <h5>Einbindung von Diensten und Inhalten Dritter</h5>
              <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                Es kann vorkommen, dass innerhalb dieses Onlineangebotes Inhalte Dritter, wie zum Beispiel Kartenmaterial von Google-Maps, RSS-Feeds oder Grafiken von anderen Webseiten eingebunden werden. Dies setzt immer voraus, dass die Anbieter dieser Inhalte (nachfolgend bezeichnet als "Dritt-Anbieter") die IP-Adresse der Nutzer wahrnehmen. Denn ohne die IP-Adresse, könnten sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, falls die Dritt-Anbieter die IP-Adresse z.B. für statistische Zwecke speichern. Soweit dies uns bekannt ist, klären wir die Nutzer darüber auf
              </p>
                <h5>Ihre Rechte als Betroffener</h5>
                <p className="lead text-md-left text-muted mb-6 mb-lg-8">
                  Wenn die jeweiligen gesetzlichen Voraussetzungen erfüllt sind, haben Sie ein Recht auf Auskunft über Ihre vom HVNB verarbeiteten personenbezogenen Daten (Art. 15 DSGVO), ein Recht auf Berichtigung Ihrer personenbezogenen Daten (Art. 16 DSGVO), ein Recht auf Löschung (Art. 17 DSGVO), ein Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO) und ein Recht auf Widerspruch gegen die Verarbeitung (Art. 21 DSGVO).
                </p>
              </div>
            </div>
        </div>
    </section>
  </Layout>
)

export default Datenschutz
